import {useCurrentCustomer} from '@salesforce/retail-react-app/app/hooks/use-current-customer'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'

export const useSfmcEvents = () => {
    // data should be an array of objects with item, quantity, price, and uniqueId.
    // form pdp page when trackcart is called, data contains array with single product VariantDetails,
    // and from cart page, contains whole updated cart

    const customer = useCurrentCustomer()

    const {app} = getConfig()
    const orgId = app?.commerceAPI?.parameters?.mid

    const trackCart = (data) => {
        window._etmc?.push(['setOrgId', orgId])
        window._etmc?.push(['setUserInfo', {email: customer?.data?.email}])
        window._etmc?.push([
            'trackCart',
            {
                cart: data
            }
        ])
    }

    // item: productCode, category: productCategory, search: serach term
    const trackPageView = ({item, category, search} = {}) => {
        window._etmc?.push(['setOrgId', orgId])
        window._etmc?.push(['setUserInfo', {email: customer?.data?.email}])
        window._etmc?.push([
            'trackPageView',
            {
                item,
                category,
                search
            }
        ])
    }

    return {
        trackCart,
        trackPageView
    }
}
