export const colors = {
    gainsboro: '#dbdbdb',
    platinum: '#E6E6E6',
    quilGray: '#CEC9C2',
    borderGray: '#7b7b7b',
    softOrange: '#EEAA77',
    silverGray: '#d9d9d9',
    light: '#EEEFE6',
    blueCol: '#2B2F31',
    platCol: '#e7e7e7',
    bgOverlay: '#00000080',
    textLight: '#5D6161',
    textDark: '#2A3030',
    iconDark: '#2B2F31',
    footerBgGradient:
        'conic-gradient(from 171.24deg at 65.6% 100%, #EEEFE6 0deg, #D5D9D0 12.69deg, #CEC9C2 285deg, #C5C5C5 355.16deg, #EEEFE6 360deg)',
    btnGradient:
        ' radial-gradient(180.3% 2239.35% at 31.13% -21.21%, #EEEFE6 0%, rgba(238, 239, 230, 0) 100%)',

    blueBgGradient:
        'linear-gradient(173.66deg, #FFFFFF 27.36%, #B2D4D5 135.61%)',
    redBgGradient:
        'linear-gradient(178.82deg, #FFFFFF 25.35%, #F3C9A8 155.25%)',
    greenbtnGradient:
        'conic-gradient(from 126.32deg at 43.62% 101.74%, #65A9AA 0deg, #EEEFE6 360deg)',
    eventSectionGreen: '#53655D',
    bgSilverGray: 'rgba(238, 239, 230, 0.37)',
    bgSilverGray1: '#F5F5F0',
    grayish: '#D1CCC5',
    secondaryGrayish: '#54635e',
    lightGrayish: '#F7F7F3',
    cloudy: '#ADA396',
    softYellow: '#EEEFE680',
    brightGray: '#EAECF0',
    darkGray: '#787878',
    chipGray: '#ECE7E0',
    black: '#000000',
    white: '#FFFFFF',
    transparent: 'transparent',
    coolGray: '#8D8D8D',
    primaryGray: '#667085',
    gunmetalGray: '#2B2F31',
    mistGray: '#DEDEDE',
    lightBeigeTransparent: 'rgba(236, 231, 224, 0.33)',
    greyTransparent: 'rgba(174, 174, 174, 0.37)',
    graySolid: 'rgba(174, 174, 174, 1)',
    paleSilver: '#DDDDDD',
    paleYellow: '#344054',
    gray: '#ACA396',
    lightWhite: '#ECE7E0',
    lightBlueGray: '#D0D5DD',
    lightGray: '#707070',
    lightBlackGray: '#888888',
    lightGrayBorder: '#8F9292',
    grayBgColor: '#EEEFE65E',
    grayColor: '#C2C2C2',
    blackBgColor: '#2a3030',
    badgeLight: '#FEE8D8',
    pmCream: '#EDEDE5',
    grayLight: '#A4ADA9',
    lightGrayBlack: '#9F9F9F',
    textGray: '#919191',
    lighterGray: '#F7F7F7',
    pastelTurquoise: '#B2D4D5'
}
