import {
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'

import {HideOnDesktop} from '@salesforce/retail-react-app/app/components/responsive'
import {
    Box,
    Button,
    Flex
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {colors} from '../../../constants'
import {PlusCircleIcon} from '../../icons'

const QuickBuyPlp = ({
    isAddToCartVisible,
    product,
    handleAddToCart,
    openedMenuId,
    setOpenedMenuId,
    isShadeSelectionDialog,
    productIsOrderable
}) => {
    const sizeAttribute = product?.variationAttributes?.find((attribute) =>
        attribute.id.includes('size')
    )
    const intl = useIntl()

    const isMenuOpen = openedMenuId === product.productId

    const isOutOfStock = !(productIsOrderable || false)

    const [selectedVariant, setSelectedVariant] = useState(undefined)

    const salePrice = product?.salePrice ?? 0
    const promotionPrice = product?.promotionPrice ?? 0
    const lowerPrice =
        promotionPrice && promotionPrice < salePrice
            ? promotionPrice
            : salePrice

    return (
        <>
            {isMenuOpen && (
                // Overlay behind the menu when it's open
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100vw"
                    height="100vh"
                    backgroundColor="transparent"
                    zIndex="0"
                    onClick={() => setOpenedMenuId(null)} // Clicking on the overlay closes the menu
                />
            )}

            <Box
                display={{
                    base: 'none',
                    lg: isAddToCartVisible ? 'block' : 'none'
                }}
                position="absolute"
                bottom="4px"
                left="10px"
                zIndex="1"
                width="100%"
            >
                <Flex
                    gap="10px"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {lowerPrice > 0 && (
                        <Button
                            isDisabled={isOutOfStock && !isShadeSelectionDialog}
                            onClick={(event) => {
                                event.preventDefault()
                                handleAddToCart(product, selectedVariant)
                                event.stopPropagation()
                            }}
                            background="white"
                            color={colors.quilGray}
                            backgroundColor="transparent"
                            fontWeight="300"
                            borderRadius="20px"
                            fontSize="14"
                            border="1px solid"
                            borderColor="transparent"
                            _hover={{
                                background: 'black',
                                color: 'white',
                                borderColor: colors.quilGray
                            }}
                            _disabled={{
                                cursor: 'not-allowed',
                                _hover: {
                                    background: 'black',
                                    color: 'white',
                                    borderColor: colors.quilGray
                                }
                            }}
                            height="fit-content"
                            px="8px"
                            py="2px"
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Add to Cart',
                                id: 'product_view.button.add_to_cart'
                            })}
                        </Button>
                    )}
                    {!isShadeSelectionDialog &&
                        sizeAttribute?.values?.map((variant) => (
                            <Button
                                key={variant.value}
                                variant="unstyled"
                                background="white"
                                px="8px"
                                color={
                                    selectedVariant?.value === variant.value
                                        ? 'white'
                                        : 'black'
                                }
                                backgroundColor={
                                    selectedVariant?.value === variant.value
                                        ? 'black'
                                        : 'transparent'
                                }
                                fontWeight="300"
                                borderRadius="20px"
                                border="1px solid"
                                borderColor="transparent"
                                fontSize="14"
                                onClick={(event) => {
                                    event.preventDefault()
                                    setSelectedVariant(variant)
                                    event.stopPropagation()
                                }}
                                _hover={{
                                    background: 'black',
                                    color: 'white',
                                    borderColor: colors.quilGray
                                }}
                                height="fit-content"
                            >
                                {variant.value}
                            </Button>
                        ))}
                </Flex>
            </Box>
            <HideOnDesktop>
                <Menu
                    isOpen={isMenuOpen}
                    onClose={() => setOpenedMenuId(null)}
                    placement="top-end"
                >
                    {lowerPrice > 0 && (
                        <MenuButton
                            as={IconButton}
                            icon={<PlusCircleIcon />}
                            backgroundColor="transparent"
                            background="transparent"
                            _hover={{
                                backgroundColor: 'transparent',
                                background: 'transparent'
                            }}
                            _disabled={{
                                backgroundColor: 'transparent',
                                background: 'transparent',
                                cursor: 'not-allowed'
                            }}
                            right="0"
                            bottom="0"
                            position="absolute"
                            zIndex="1"
                            isDisabled={
                                isOutOfStock &&
                                !isShadeSelectionDialog &&
                                !sizeAttribute?.values
                            }
                            onClick={(event) => {
                                event.preventDefault()
                                if (
                                    isOutOfStock &&
                                    !isShadeSelectionDialog &&
                                    !sizeAttribute?.values
                                ) {
                                    return
                                }
                                if (
                                    isShadeSelectionDialog ||
                                    !sizeAttribute?.values
                                ) {
                                    handleAddToCart(product)
                                    return
                                }
                                setOpenedMenuId((current) =>
                                    current === product.productId
                                        ? null
                                        : product.productId
                                )
                                event.stopPropagation()
                            }}
                            _active={{
                                backgroundColor: 'transparent',
                                background: 'transparent'
                            }}
                        />
                    )}
                    <MenuList gap="4px" display="flex" flexDirection="column">
                        {sizeAttribute?.values?.map((variant) => (
                            <MenuItem
                                key={variant.value}
                                variant="unstyled"
                                background="white"
                                color={
                                    selectedVariant?.value === variant.value
                                        ? 'white'
                                        : 'black'
                                }
                                backgroundColor={
                                    selectedVariant?.value === variant.value
                                        ? 'black'
                                        : 'transparent'
                                }
                                fontWeight="300"
                                fontSize="14"
                                onClick={(event) => {
                                    event.preventDefault()
                                    handleAddToCart(product, variant)
                                    event.stopPropagation()
                                }}
                                _hover={{
                                    background: 'black',
                                    color: 'white'
                                }}
                                _active={{
                                    background: 'black',
                                    color: 'white'
                                }}
                                height="fit-content"
                            >
                                {variant.value}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </HideOnDesktop>
        </>
    )
}

QuickBuyPlp.propTypes = {
    isAddToCartVisible: PropTypes.bool,
    product: PropTypes.shape({
        productId: PropTypes.string,
        salePrice: PropTypes.number,
        promotionPrice: PropTypes.number,
        variationAttributes: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                value: PropTypes.string
            })
        )
    }),
    handleAddToCart: PropTypes.func,
    openedMenuId: PropTypes.string, // The ID of the currently opened menu
    setOpenedMenuId: PropTypes.func,
    isShadeSelectionDialog: PropTypes.bool,
    productIsOrderable: PropTypes.bool
}

export default QuickBuyPlp
