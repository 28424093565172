import React from 'react'
import {
    Box,
    useRadio
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'

const CustomRoundedRadio = (props) => {
    const {getInputProps, getRadioProps, state} = useRadio(props)

    const width = props?.width || '28px'
    const height = props?.width || '28px'

    const input = getInputProps()
    const checkbox = getRadioProps()

    return (
        <Box as="label">
            <input {...input} hidden />
            <Box
                {...checkbox}
                cursor="pointer"
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1px solid black" // Outer black border
                borderRadius="full"
                width={width}
                height={height}
                padding="4px" // White space effect
                background="white"
                outline="none"
                _checked={{
                    bg: 'white',
                    color: 'white'
                }}
                _focus={{
                    boxShadow: 'none',
                    color: 'gray.500'
                }}
            >
                {state.isChecked && (
                    <svg
                        viewBox="0 0 200 200"
                        focusable="false"
                        class="chakra-icon css-vh62rh"
                    >
                        <circle cx="100" cy="100" r="60" fill="black"></circle>
                    </svg>
                )}
            </Box>
        </Box>
    )
}
CustomRoundedRadio.propTypes = {
    children: PropTypes.node
}
export default CustomRoundedRadio
