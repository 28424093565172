import React from 'react'

import {AccordionCollapsedIcon, AccordionOpenIcon} from '../icons'

const CustomAccordionIcon = ({isAccordionActive, boxSize = '18px'}) => {
    return (
        <>
            {isAccordionActive ? (
                <AccordionCollapsedIcon boxSize={boxSize} />
            ) : (
                <AccordionOpenIcon boxSize={boxSize} />
            )}
        </>
    )
}

CustomAccordionIcon.propTypes

export default CustomAccordionIcon
