import {useState, useEffect, useRef, useCallback} from 'react'

// Define the options type for the hook

/**
 * Custom hook for observing element intersection with the viewport
 * @param options - Intersection Observer options
 * @returns Tuple of ref setter, inView status, and intersection entry
 */
export function useIntersectionObserver(
    options = {once: false, threshold: 0, root: null, rootMargin: '0px'}
) {
    const {
        once = false,
        threshold = 0,
        root = null,
        rootMargin = '0px'
    } = options

    const [inView, setInView] = useState(false)
    const [entry, setEntry] = useState(null)
    const [node, setNode] = useState(null)

    const handleIntersect = useCallback(
        (entries) => {
            const [observedEntry] = entries

            setInView(observedEntry.isIntersecting)
            setEntry(observedEntry)

            // Stop observing if 'once' option is true and element is intersecting
            if (once && observedEntry.isIntersecting) {
                observer.current?.disconnect()
            }
        },
        [once]
    )

    const observer = useRef(null)

    // Ref callback to set the node
    const ref = useCallback((node) => {
        setNode(node)
    }, [])

    const resetObserver = useCallback(() => {
        if (observer.current && node) {
            // Disconnect the current observer
            observer.current.disconnect()
            // Re-observe the node (last product card)
            observer.current.observe(node)
        }
    }, [node])

    useEffect(() => {
        // Only create observer if a node is attached
        if (!node) return

        // Disconnect previous observer if it exists
        if (observer.current) {
            observer.current.disconnect()
        }

        // Create new Intersection Observer
        observer.current = new IntersectionObserver(handleIntersect, {
            root,
            rootMargin,
            threshold
        })

        // Start observing the element
        observer.current.observe(node)

        // Cleanup function to disconnect observer
        return () => {
            if (observer.current) {
                observer.current.disconnect()
            }
        }
    }, [node, handleIntersect, root, rootMargin, threshold])

    return [ref, inView, entry, resetObserver]
}
