import PropTypes from 'prop-types'
import React, {useMemo} from 'react'

import {
    Flex,
    RangeSlider,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    RangeSliderTrack,
    Text
} from '@chakra-ui/react'

import {colors} from '../../../constants'

const RangeSelectorRefinement = ({filter, toggleFilter, selectedFilters}) => {
    const handleRangeChange = (value) => {
        // value = [min, max]
        // convert to "(min..max)"
        const range = `(${value[0]}..${value[1]})`
        toggleFilter(
            {value: range},
            filter.attributeId,
            selectedFilters.includes(range),
            false
        )
    }

    const minMaxRange = useMemo(() => {
        const [min = 0, max = 0] = filter.values[0].value
            .slice(1, -1)
            .split('..')
            .map(Number)

        return {min, max}
    }, [filter])

    const selectedFilter =
        selectedFilters.length > 0
            ? selectedFilters[0]
            : `(${minMaxRange.min}..${minMaxRange.max})`
    const rangeMatch = selectedFilter.match(/\((\d+)\.\.(\d+)\)/)
    const range = rangeMatch
        ? `$${rangeMatch[1]} - $${rangeMatch[2]}`
        : `$${minMaxRange.min} - $${minMaxRange.max}`

    const ariaLabel = {'aria-label': ['min', 'max']}
    return (
        <Flex flexDirection="column" gap="8px">
            <RangeSlider
                {...ariaLabel}
                defaultValue={[minMaxRange.min, minMaxRange.max]}
                min={minMaxRange.min}
                max={minMaxRange.max}
                onChangeEnd={handleRangeChange}
                minStepsBetweenThumbs={1}
            >
                <RangeSliderTrack bg={colors.borderGray} h="1px">
                    <RangeSliderFilledTrack
                        color={colors.borderGray}
                        bg="black"
                    />
                </RangeSliderTrack>
                <RangeSliderThumb
                    index={0}
                    bg="black"
                    height="12px"
                    width="12px"
                />
                <RangeSliderThumb
                    index={1}
                    bg="black"
                    height="12px"
                    width="12px"
                />
            </RangeSlider>
            <Text //styleName: Roboto light/16;
                fontFamily="Roboto"
                fontSize="16px"
                fontWeight="300"
                lineHeight="18.75px"
                textAlign="left"
            ></Text>
            {range}
        </Flex>
    )
}

RangeSelectorRefinement.propTypes = {
    filter: PropTypes.object,
    toggleFilter: PropTypes.func,
    selectedFilters: PropTypes.array
}

export default RangeSelectorRefinement
