import {gql} from 'graphql-request'

export const getGlobalSettingsQuery = gql`
    query plpStaticFilters {
        globalSettingsCollection(limit: 1) {
            items {
                plpViewStyle
            }
        }
    }
`
