import {motion} from 'framer-motion'
import PropTypes from 'prop-types'
import React from 'react'

import {useMultiStyleConfig} from '@salesforce/retail-react-app/app/components/shared/ui'

import {styleConfigChakraKeys} from '../../constants'
import Link from '../link'

const AddToCartButton = ({onClick, text, ...props}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.addToCartButton)

    return (
        <Link
            {...styles.addToCartButton}
            onLinkClick={onClick}
            as={motion.button}
            whileHover={{
                backgroundPosition: '0% 0'
            }}
            type="primary"
            {...props}
            text={text}
        />
    )
}

AddToCartButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string
}

AddToCartButton.displayName = 'AddToCartButton'

export default AddToCartButton
