import _ from 'lodash'
import {Menu, MenuButton, MenuItem, MenuList} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, {useMemo} from 'react'
import {useHistory} from 'react-router-dom'

import {
    Box,
    Button,
    Text
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {colors} from '../../constants'
import {ChevronSelectDownIcon} from '../icons'

const SizeSelector = ({id, selectedItem, values, handleSelect}) => {
    const history = useHistory()

    const handleChange = (item) => {
        handleSelect(item)
        if (!item.href) return
        history.replace(item.href)
    }

    const label = useMemo(() => {
        if (!id) return ''
        const regex = new RegExp('size(ml|oz)', 'gi')
        return id.replace(regex, 'size')
    }, [id])

    return (
        <Box>
            <Text
                marginBottom="10px"
                fontFamily="Roboto"
                fontWeight="300"
                fontSize="14px"
                textTransform="capitalize"
            >
                {label}
            </Text>
            {values.length > 1 ? (
                <Menu>
                    <MenuButton
                        border="1px solid"
                        borderColor={colors.textLight}
                        as={Button}
                        backgroundColor="transparent"
                        textColor="black"
                        placeholder="Select"
                        _hover={{}}
                        _active={{}}
                        sx={{
                            span: {
                                display: 'flex',
                                justifyContent: 'flex-start',
                                fontFamily: 'Roboto',
                                fontWeight: 300
                            }
                        }}
                        fontSize="14px"
                        height={11}
                        width={['100%', '100%', '100%', '100%']}
                        borderRadius="20px"
                        fontFamily="Roboto"
                        rightIcon={
                            <Box as={ChevronSelectDownIcon} boxSize="14px" />
                        }
                    >
                        <Text fontSize="14px">{selectedItem}</Text>
                    </MenuButton>
                    <MenuList
                        id="menu-list"
                        rootProps={{
                            width: ['100%', '100%', '100%', '100%'],
                            maxWidth: '300px'
                        }}
                    >
                        {values.map((item) => {
                            const isSelected = selectedItem === item?.value
                            return (
                                <MenuItem
                                    height={11}
                                    fontFamily="Roboto"
                                    fontWeight={300}
                                    key={item?.value}
                                    isDisabled={!item?.orderable}
                                    onClick={() => {
                                        if (item && item?.orderable)
                                            handleChange(item)
                                    }}
                                    value={item?.value}
                                    backgroundColor={
                                        isSelected
                                            ? '#cccccc'
                                            : 'var(--menu-bg)'
                                    }
                                >
                                    {item?.name}
                                </MenuItem>
                            )
                        })}
                    </MenuList>
                </Menu>
            ) : (
                <Button
                    height="49px"
                    border="1px solid #E6E6E6"
                    borderRadius="33px"
                    padding="8px 12px"
                    colorScheme="none"
                    onClick={() => handleChange(values[0])}
                    maxWidth="121px"
                    minWidth="121px"
                    width="fit-content"
                    fontWeight="400"
                >
                    <Text
                        background={
                            selectedItem === values[0]?.value
                                ? '#F3F3F3'
                                : 'white'
                        }
                        borderRadius="33px"
                        color="black"
                        fontSize="16px"
                        padding="6px 30px"
                    >
                        {values[0]?.name}
                    </Text>
                </Button>
            )}
        </Box>
    )
}

SizeSelector.propTypes = {
    id: PropTypes.string,
    selectedItem: PropTypes.string,
    values: PropTypes.array,
    handleSelect: PropTypes.func
}

export default SizeSelector
