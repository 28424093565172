import {useMemo} from 'react'

/**
 * @typedef {object} ProductPromotion
 * @property {string} id
 * @property {string} name
 * @property {string} callOut
 * @property {string} details
 */

/**
 * @typedef {object} ProductPricing
 * @property {string} currency
 * @property {string} salePrice
 * @property {string} originalPrice
 * @property {string} promotionPrice
 * @property {ProductPromotion} promoDetails
 */

/**
 * Gets the product pricing using the (if available) extended product
 * attributes (c_extend)
 * @param {object} Product
 * @return {ProductPricing}
 */
function useProductPricing(product) {
    return useMemo(() => {
        if (product) {
            if (
                product.c_extend &&
                product.c_extend.priceInfo &&
                product.c_extend.priceInfo.salePrice?.value
            ) {
                return {
                    currency: product.c_extend.priceInfo.salePrice?.currency,
                    salePrice: product.c_extend.priceInfo.salePrice?.value,
                    originalPrice:
                        product.c_extend.priceInfo.originalPrice?.value,
                    promotionPrice:
                        product.c_extend.priceInfo.promotionPrice?.value,
                    promoDetails:
                        product.c_extend.priceInfo.promotionPrice?.promoDetails
                }
            } else {
                return {
                    currency: product.currency,
                    salePrice: product.price,
                    originalPrice: product.price,
                    promotionPrice: null,
                    promoDetails: null
                }
            }
        } else {
            return {
                currency: null,
                salePrice: null,
                originalPrice: null,
                promotionPrice: null,
                promoDetails: null
            }
        }
    }, [product])
}

export default useProductPricing
