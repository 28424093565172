import PropTypes from 'prop-types'
import React, {useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'

import {
    Box,
    Fade,
    Flex,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useCurrentBasket} from '@salesforce/retail-react-app/app/hooks/use-current-basket'
import AddToCartButton from '../../../components/add-to-cart-button/add-to-cart-button'
import PdpQuantityPicker from '../../../components/pdp-quantity-picker'
import ProductPricing from '../../../components/product-pricing'
import SizeSelector from '../../../components/size-selector'
import SwatchGroup from '../../../components/swatch-group'
import Swatch from '../../../components/swatch-group/swatch'
import {API_ERROR_MESSAGE, colors} from '../../../constants'
import useProductPricing from '../../../hooks/use-product-pricing'
import {useStyledToast} from '../../../hooks/use-styled-toast'
import LoadingSpinner from '@salesforce/retail-react-app/app/components/loading-spinner'

const placeholderImage =
    'https://edge.disstg.commercecloud.salesforce.com/dw/image/v2/ZZRF_001/on/demandware.static/-/Sites-apparel-m-catalog/default/dw3d8caebf/images/swatch/PG.10254453.JJ5AAXX.CP.jpg'

const AddToCartDialog = ({
    product,
    isOpen,
    handleClose,
    handleAddToCart,
    handleCartDrawerOpen,
    showMaximumQuantityErrorMessage,
    derivedProduct,
    setSelectedItem,
    isProductLoading
}) => {
    const {
        stepQuantity,
        quantity,
        setQuantity,
        variationAttributes,
        minOrderQuantity,
        maxOrderQuantity,
        ats,
        isOutOfStock,
        inventoryMessage,
        showLoading: _showLoading,
        variant,
        isProductASet,
        showInventoryMessage,
        preorderable
    } = derivedProduct

    const showLoading = useMemo(() => {
        return isProductLoading || _showLoading
    }, [isProductLoading, _showLoading])

    const {showToast} = useStyledToast()
    const handleSelect = (item) => {
        setSelectedItem(item.name)
    }
    const {isLoading: isBasketLoading} = useCurrentBasket()

    const [showOptionsMessage, setShowOptionsMessage] = useState(false)
    const [showQuantityErrorMessage, setShowQuantityErrorMessage] =
        useState(false)

    const {salePrice, originalPrice, promotionPrice} =
        useProductPricing(product)

    const history = useHistory()
    const intl = useIntl()
    const errorContainerRef = useRef(null)

    const validateOrderability = (
        variant,
        quantity,
        stockLevel,
        selectedProduct
    ) => {
        if (
            selectedProduct?.type?.['variant'] ||
            selectedProduct?.type?.['master'] ||
            selectedProduct?.productType?.['variant'] ||
            selectedProduct?.productType?.['master']
        ) {
            return (
                variant?.orderable &&
                quantity > 0 &&
                (quantity <= stockLevel ||
                    quantity <= selectedProduct?.inventory?.stockLevel)
            )
        }
        if (selectedProduct?.type['item']) {
            return quantity > 0 && quantity <= stockLevel
        }

        return variant?.orderable && quantity > 0 && quantity <= stockLevel
    }

    const handleChange = (_, href) => {
        if (!href) return
        setSelectedItem(variant?.productId)
        history.replace(href)
    }

    const addToCart = (variant, quantity) => {
        handleAddToCart(product, variant, quantity)
    }

    const renderActionButtons = () => {
        const buttons = []
        const buttonText = {
            update: intl.formatMessage({
                defaultMessage: 'Update',
                id: 'product_view.button.update'
            }),
            addToCartPreorder: intl.formatMessage({
                defaultMessage: 'Preorder',
                id: 'product_view.button.preorder'
            }),
            addToCart: intl.formatMessage({
                defaultMessage: 'Add to Cart',
                id: 'product_view.button.add_to_cart'
            }),
            addToWishlist: intl.formatMessage({
                defaultMessage: 'Add to wishlist',
                id: 'product_view.button.add_to_wishlist'
            }),
            outOfStock: intl.formatMessage({
                defaultMessage: 'Out of Stock',
                id: 'product_view.button.out_of_stock'
            }),
            addSetToCart: intl.formatMessage({
                defaultMessage: 'Add Set to Cart',
                id: 'product_view.button.add_set_to_cart'
            }),
            shopNow: intl.formatMessage({
                defaultMessage: 'Shop Now',
                id: 'product_view.button.shop_now'
            })
        }
        const showError = () => {
            showToast({
                message: intl.formatMessage(API_ERROR_MESSAGE),
                status: 'error'
            })
        }

        const validateAndShowError = (opts = {}) => {
            const {scrollErrorIntoView = true} = opts
            // Validate that all attributes are selected before proceeding.
            const hasValidSelection = validateOrderability(
                variant,
                quantity,
                ats,
                product
            )
            const showError = !isProductASet && !hasValidSelection
            const quantityError =
                quantity <= product?.inventory?.stockLevel
                    ? false
                    : quantity > ats
            const scrollToError = showError && scrollErrorIntoView

            setShowOptionsMessage(showError)
            setShowQuantityErrorMessage(quantityError)

            if (scrollToError && errorContainerRef) {
                errorContainerRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
            }

            return hasValidSelection
        }

        const handleCartItem = async () => {
            const hasValidSelection = validateAndShowError()

            if (!hasValidSelection) {
                return null
            }

            if (!addToCart) return null

            try {
                const itemsAdded = await addToCart(variant, quantity)

                if (itemsAdded) {
                    handleCartDrawerOpen()
                    // Assuming `itemsAdded` is truthy when the item is successfully added to the cart
                }

                // Open modal only when `addToCart` returns some data
                // It's possible that the item has been added to cart, but we don't want to open the modal.
                // See wishlist_primary_action for example.
            } catch (e) {
                showError()
            }
        }
        if (addToCart) {
            buttons.push(
                <AddToCartButton
                    key="cart-button"
                    onClick={handleCartItem}
                    disabled={
                        isBasketLoading || isOutOfStock || showInventoryMessage
                    }
                    isLoading={isBasketLoading}
                    text={
                        (isOutOfStock && buttonText.outOfStock) ||
                        (product?.id === '120534' && buttonText.shopNow) ||
                        (isProductASet && buttonText.addSetToCart) ||
                        (preorderable && buttonText.addToCartPreorder) ||
                        buttonText.addToCart
                    }
                />
            )
        }

        return buttons
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay zIndex={1500} />
            <ModalContent
                borderRadius="20px"
                overflow="hidden"
                containerProps={{
                    zIndex: 1501
                }}
                width={{base: '90%', lg: '100%'}}
                maxWidth={{base: '556px', md: '524px'}}
                padding="40px"
            >
                <ModalCloseButton
                    color="black"
                    _focusVisible={{boxShadow: 'none'}}
                    fontSize="18px"
                    zIndex="1"
                    top="40px"
                    right="40px"
                />
                <ModalHeader
                    fontFamily="Raleway"
                    fontSize={{base: '16px', lg: '32px'}}
                    fontWeight="400"
                    lineHeight="37.57px"
                    textAlign="left"
                    padding="0px"
                    marginBottom="24px"
                >
                    {intl.formatMessage({
                        id: 'plp.add-to-cart.heading',
                        defaultMessage: 'Select your Product'
                    })}
                </ModalHeader>
                <ModalBody padding="0">
                    {showLoading && (
                        <Flex
                            width="100%"
                            minHeight="250px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <LoadingSpinner />
                        </Flex>
                    )}
                    {!showLoading && (
                        <>
                            <VStack gap="26px" alignItems="flex-start">
                                <VStack gap="12px" alignItems="flex-start">
                                    <Text
                                        fontFamily="Roboto"
                                        fontSize="20px"
                                        fontWeight="300"
                                        lineHeight="23.44px"
                                        textAlign="left"
                                    >
                                        {product?.brand}
                                    </Text>
                                    <Heading
                                        fontFamily="Raleway"
                                        fontSize="32px"
                                        fontWeight="400"
                                        lineHeight="37.57px"
                                        textAlign="left"
                                    >
                                        {product?.name}
                                    </Heading>
                                </VStack>
                                <ProductPricing
                                    product={{
                                        salePrice,
                                        regularPrice: originalPrice,
                                        promotionPrice,
                                        currency: product?.currency,
                                        isPdp: true
                                    }}
                                    scope="tile"
                                />
                            </VStack>
                            <VStack
                                gap="23px"
                                alignItems="flex-start"
                                marginTop="26px"
                            >
                                {variationAttributes?.map(
                                    (variationAttribute) => {
                                        const {
                                            id,
                                            name,
                                            selectedValue,
                                            values = []
                                        } = variationAttribute
                                        return [
                                            'size',
                                            'sizeMl',
                                            'sizeOz'
                                        ].includes(id) ? (
                                            <SizeSelector
                                                id={id}
                                                selectedItem={
                                                    selectedValue?.value
                                                }
                                                values={values}
                                                handleSelect={handleSelect}
                                                key={id}
                                            />
                                        ) : (
                                            <SwatchGroup
                                                key={id}
                                                onChange={handleChange}
                                                variant={id}
                                                value={selectedValue?.value}
                                                displayName={
                                                    selectedValue?.name || ''
                                                }
                                                label={name}
                                            >
                                                {values.map(
                                                    ({
                                                        href,
                                                        name,
                                                        image,
                                                        value,
                                                        orderable
                                                    }) => {
                                                        return (
                                                            <Swatch
                                                                key={value}
                                                                href={href}
                                                                disabled={
                                                                    !orderable
                                                                }
                                                                value={value}
                                                                name={name}
                                                            >
                                                                <Box
                                                                    height="100%"
                                                                    width="100%"
                                                                    minWidth="26px"
                                                                    backgroundRepeat="no-repeat"
                                                                    backgroundSize="cover"
                                                                    backgroundImage={
                                                                        image
                                                                            ? `url("${
                                                                                  image.disBaseLink ||
                                                                                  image.link
                                                                              }")`
                                                                            : `url(${placeholderImage})`
                                                                    }
                                                                />
                                                            </Swatch>
                                                        )
                                                    }
                                                )}
                                            </SwatchGroup>
                                        )
                                    }
                                )}
                            </VStack>
                            <VStack align="stretch" marginTop="26px">
                                <HStack>
                                    {!isOutOfStock && (
                                        <PdpQuantityPicker
                                            id="quantity"
                                            step={stepQuantity}
                                            value={quantity}
                                            min={minOrderQuantity}
                                            max={Math.min(
                                                maxOrderQuantity,
                                                ats
                                            )}
                                            onChange={(
                                                stringValue,
                                                numberValue
                                            ) => {
                                                // Set the Quantity of product to value of input if value number
                                                if (numberValue >= 0) {
                                                    setQuantity(numberValue)
                                                } else if (stringValue === '') {
                                                    // We want to allow the use to clear the input to start a new input so here we set the quantity to '' so NAN is not displayed
                                                    // User will not be able to add '' qauntity to the cart due to the add to cart button enablement rules
                                                    setQuantity(stringValue)
                                                }
                                            }}
                                            onBlur={(e) => {
                                                // Default to 1the `minOrderQuantity` if a user leaves the box with an invalid value
                                                const value = e.target.value
                                                if (
                                                    parseInt(value) < 0 ||
                                                    value === ''
                                                ) {
                                                    setQuantity(
                                                        minOrderQuantity
                                                    )
                                                }
                                            }}
                                            onFocus={(e) => {
                                                // This is useful for mobile devices, this allows the user to pop open the keyboard and set the
                                                // new quantity with one click. NOTE: This is something that can be refactored into the parent
                                                // component, potentially as a prop called `selectInputOnFocus`.
                                                e.target.select()
                                            }}
                                        />
                                    )}
                                    <Box w="100%">
                                        <Box>{renderActionButtons()}</Box>
                                    </Box>
                                </HStack>

                                {((ats &&
                                    quantity >=
                                        Math.min(maxOrderQuantity, ats)) ||
                                    showMaximumQuantityErrorMessage) && (
                                    <Text
                                        marginTop="6px"
                                        fontSize="12px"
                                        fontWeight="400"
                                        color={colors.graySolid}
                                    >
                                        {intl.formatMessage({
                                            id: 'product_view.quantity_limit',
                                            defaultMessage:
                                                'Maximum Quantity is reached'
                                        })}
                                    </Text>
                                )}

                                {!showLoading && !!inventoryMessage && (
                                    <Text
                                        marginTop="19px"
                                        fontSize="12px"
                                        fontWeight="300"
                                    >
                                        {inventoryMessage}
                                    </Text>
                                )}
                            </VStack>
                            {(!showLoading &&
                                showOptionsMessage &&
                                !showQuantityErrorMessage && (
                                    <Box>
                                        <Fade in={true}>
                                            <Text
                                                color="orange.600"
                                                fontWeight={600}
                                                marginBottom={8}
                                            >
                                                {intl.formatMessage({
                                                    id: 'product_view.select.options',
                                                    defaultMessage:
                                                        'Please select all your options above'
                                                })}
                                            </Text>
                                        </Fade>
                                    </Box>
                                )) ||
                                (!showLoading && showQuantityErrorMessage && (
                                    <Box>
                                        <Fade in={true}>
                                            <Text
                                                color="orange.600"
                                                fontWeight={600}
                                                marginBottom={8}
                                            >
                                                {intl.formatMessage({
                                                    id: 'product_view.quantity_limit',
                                                    defaultMessage:
                                                        'Quantity selected more than available in stock'
                                                })}
                                            </Text>
                                        </Fade>
                                    </Box>
                                ))}
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export const derivedProductPropTypes = {
    showLoading: PropTypes.bool.isRequired,
    showInventoryMessage: PropTypes.bool.isRequired,
    inventoryMessage: PropTypes.string,
    variationAttributes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired
                })
            ).isRequired
        })
    ),
    quantity: PropTypes.number.isRequired,
    minOrderQuantity: PropTypes.number.isRequired,
    maxOrderQuantity: PropTypes.number.isRequired,
    stepQuantity: PropTypes.number.isRequired,
    variationParams: PropTypes.object,
    setQuantity: PropTypes.func.isRequired,
    variant: PropTypes.object,
    ats: PropTypes.number.isRequired,
    basePrice: PropTypes.string,
    discountPrice: PropTypes.string,
    isOutOfStock: PropTypes.bool.isRequired,
    preorderable: PropTypes.bool.isRequired,
    c_valueSet: PropTypes.bool
}

AddToCartDialog.propTypes = {
    product: PropTypes.shape({
        productId: PropTypes.string,
        variationAttributes: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                value: PropTypes.string
            })
        ),
        inventory: PropTypes.object,
        brand: PropTypes.string,
        price: PropTypes.number,
        id: PropTypes.string,
        currency: PropTypes.string,
        name: PropTypes.string
    }),
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAddToCart: PropTypes.func,
    handleCartDrawerOpen: PropTypes.func,
    showMaximumQuantityErrorMessage: PropTypes.bool,
    isProductLoading: PropTypes.bool,
    setSelectedItem: PropTypes.func,
    derivedProduct: derivedProductPropTypes
}

export default AddToCartDialog
