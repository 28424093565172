import {
    Flex,
    Stack,
    Text,
    Box
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {validatePassword} from '../../utils/password-utils'
import {colors} from '../../constants'

/**
 * UI component for styling password requirement line with a bullet
 */
const PasswordRequirement = ({children}) => {
    return (
        <Flex align="center" paddingLeft={['23px', '26px']}>
            <Box
                as="span"
                marginRight="8px"
                height="4px"
                width="4px"
                borderRadius="full"
                backgroundColor={colors.lightGray}
            />
            <Text
                fontSize="12px"
                color={colors.lightGray}
                fontWeight="300"
                lineHeight="18px"
                fontFamily="Roboto"
            >
                {children}
            </Text>
        </Flex>
    )
}

PasswordRequirement.propTypes = {
    /** Should it render in valid state */
    isValid: PropTypes.bool,

    /** The requirement text */
    children: PropTypes.any
}

/**
 * Renders a list of password requirments. Each requirement line toggles to its `isValid`
 * state when the given password value meets the associated critieria.
 */
const PasswordRequirements = ({value}) => {
    const pwValidations = validatePassword(value)

    return (
        <Stack spacing={1}>
            <PasswordRequirement isValid={pwValidations.hasMinChars}>
                <FormattedMessage
                    id="password_requirements.error.eight_letter_minimum"
                    defaultMessage="Be at least 8 characters in length."
                    description="Password requirement"
                />
            </PasswordRequirement>
            <PasswordRequirement isValid={pwValidations.hasUppercase}>
                <FormattedMessage
                    id="password_requirements.error.one_uppercase_letter"
                    defaultMessage="Contain an uppercase letter."
                    description="Password requirement"
                />
            </PasswordRequirement>
            <PasswordRequirement isValid={pwValidations.hasLowercase}>
                <FormattedMessage
                    id="password_requirements.error.one_lowercase_letter"
                    defaultMessage="Contain a lowercase letter."
                    description="Password requirement"
                />
            </PasswordRequirement>
            <PasswordRequirement isValid={pwValidations.hasNumber}>
                <FormattedMessage
                    defaultMessage="Contain a digit (0-9)."
                    description="Password requirement"
                    id="password_requirements.error.one_number"
                />
            </PasswordRequirement>
            <PasswordRequirement isValid={pwValidations.hasNoSpace}>
                <FormattedMessage
                    id="password_requirements.error.no_space"
                    defaultMessage="Not Include spaces."
                    description="Password requirement"
                />
            </PasswordRequirement>
            <PasswordRequirement isValid={pwValidations.hasSpecialChar}>
                <FormattedMessage
                    id="password_requirements.error.one_special_character"
                    defaultMessage="Contain a special character."
                    description="Password requirement"
                />
            </PasswordRequirement>
        </Stack>
    )
}

PasswordRequirements.propTypes = {
    /** The password to check against */
    value: PropTypes.string
}

export default PasswordRequirements
