import PropTypes from 'prop-types'
import React from 'react'

import {CloseIcon} from '@salesforce/retail-react-app/app/components/icons'
import {Button} from '@salesforce/retail-react-app/app/components/shared/ui'
import {colors} from '../../../constants'

const SelectedRefinementButton = ({type, text, isCloseIcon, onClick}) => {
    return (
        <Button
            width="fit-content"
            height="36px"
            color={type == 'filter' ? colors.black : colors.white}
            backgroundColor={
                type == 'filter' ? colors.paleSilver : colors.black
            }
            display="flex"
            alignItems="center"
            justifyContent={type == 'filter' ? 'space-between' : 'center'}
            gap="16px"
            size="sm"
            borderRadius="20px"
            fontSize="14px"
            lineHeight="16.41px"
            fontWeight="300"
            fontFamily="Roboto"
            padding="12px 14px"
            textAlign="center"
            onClick={onClick}
            _hover={{
                backgroundColor:
                    type == 'filter' ? colors.paleSilver : colors.black,
                color: type == 'filter' ? colors.black : colors.white
            }}
        >
            {text}
            {isCloseIcon && <CloseIcon color={colors.black} boxSize={4} />}
        </Button>
    )
}

SelectedRefinementButton.propTypes = {
    type: PropTypes.oneOf(['filter', 'clear']),
    text: PropTypes.string,
    isCloseIcon: PropTypes.bool,
    onClick: PropTypes.func
}

export default SelectedRefinementButton
