import {
    FormLabel,
    HStack,
    useRadioGroup,
    Text
} from '@salesforce/retail-react-app/app/components/shared/ui'
import React from 'react'
import CustomRoundedRadio from '.'
import PropTypes from 'prop-types'

const RadioContainer = ({options, value, name, onChange, radioStyles = {}}) => {
    const {getRootProps, getRadioProps} = useRadioGroup({
        name,
        value: value,
        onChange: (val) => {
            onChange(val)
        }
    })

    const group = getRootProps()
    return (
        <HStack {...group}>
            {options.map(({label, value, width, height}) => {
                const radio = getRadioProps({
                    value: value?.toString(),
                    width: width?.toString(),
                    height: height?.toString()
                })

                return (
                    <FormLabel
                        key={value}
                        display="flex"
                        alignItems="center"
                        gap="6px"
                        marginRight="14px"
                    >
                        <CustomRoundedRadio {...radio} />
                        <Text fontWeight="400" sx={radioStyles}>
                            {label}
                        </Text>
                    </FormLabel>
                )
            })}
        </HStack>
    )
}

RadioContainer.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
                .isRequired,
            height: PropTypes.string,
            width: PropTypes.string
        })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    radioStyles: PropTypes.object
}

export default RadioContainer
