export const languageAliases = {
    en: {
        path: '/us',
        locale: 'en-CA',
        sfccLocale: 'en_CA',
        localeName: 'en',
        booxiLocale: 'eng',
        name: 'English'
    },
    fr: {
        path: '/fr',
        locale: 'fr-CA',
        sfccLocale: 'fr_CA',
        localeName: 'fr',
        booxiLocale: 'fre',
        name: 'French'
    }
}
