import {colors} from '../../../constants'

export default {
    baseStyle: {
        container: {
            minWidth: 'xs',
            width: 'full',
            backgroundColor: 'white'
        },
        content: {
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: {base: '16px', xl: '36px'},
            paddingRight: {base: '7px', xl: '36px'},
            paddingTop: {base: '4px', xl: '11px'},
            paddingBottom: {base: '8px', xl: '12px'},
            margin: 'auto'
        },
        searchBar: {
            width: '100%',
            border: `1px solid ${colors.gainsboro}`,
            borderRadius: '45px',
            height: '33px',
            paddingLeft: {base: '21px', xl: '44px'},
            paddingRight: {base: '90px', xl: '8px'},
            marginRight: {xl: '24px'},
            backgroundColor: {base: 'white', xl: `${colors.gainsboro}`},
            fontSize: {base: '13px', xl: '14px'},
            _hover: {
                border: `1px solid ${colors.gainsboro}`
            },
            _focus: {
                border: `1px solid ${colors.gainsboro}`,
                backgroundColor: {base: 'white', xl: `${colors.gainsboro}`}
            }
        },
        bodyContainer: {
            flex: '1'
        },
        burger: {
            order: 1
        },
        burgerAnim: {
            position: 'relative',
            width: '24px',
            height: '24px',
            cursor: 'pointer',
            transform: 'translateY(-10px) translateX(10px)'
        },
        burgerSpan: {
            width: '24px',
            height: '1px',
            backgroundColor: 'black',
            display: 'block',
            marginBottom: '2px',
            position: 'absolute',
            marginTop: '24px',
            transition: 'all .2s ease-in'
        },
        logo: {
            width: {base: '92px', xl: '117px'},
            height: {base: '21px', xl: '26px'}
        },
        bookServicesBtn: {
            background: colors.quilGray,
            backgroundColor: colors.quilGray,
            color: 'black',
            padding: '8px 14px',
            height: 'auto',  
            width: 'auto',
            minWidth: 'auto',
            fontSize: '16px',
            borderRadius: '34px',
            display: {base: 'none', xl: 'block'},
            fontWeight: '300',
            _hover: {
                background: colors.grayish,
                backgroundColor: colors.grayish,
                textDecoration: 'none'
            }
        },
        searchIcon: {
            width: '25.26px',
            height: '25.26px',
            marginTop: '1px',
            alignSelf: 'center',
            cursor: 'pointer',
            marginRight: 0,
            fontSize: 'md'
        },
        iconBtn: {
            backgroundColor: 'transparent',
            padding: 0,
            minWidth: 'auto',
            width: 'auto',
            height: 'auto',
            lineHeight: 'unset',
            _hover: {
                backgroundColor: 'transparent'
            }
        },
        divider: {
            backgroundColor: `${colors.platinum}`,
            height: '44px',
            marginLeft: '20px',
            marginRight: '30px',
            width: '1px',
            display: {base: 'none', xl: 'block'}
        },
        accountIcon: {
            width: '55px',
            height: '26.02px',
            cursor: 'pointer',
            _focus: {
                boxShadow: 'outline'
            },
            _focusVisible: {
                outline: 0
            }
        },
        cartIcon: {
            height: '25.26px'
        },
        arrowDown: {
            height: 11,
            marginRight: 0,
            position: 'realative',
            zIndex: 1,
            alignSelf: ['self-start', 'self-start', 'self-start', 'auto'],
            cursor: 'pointer',
            _focus: {
                boxShadow: 'outline'
            },
            _focusVisible: {
                outline: 0
            },
            display: {base: 'none', xl: 'block'},
            opacity: 0,
            marginLeft: '-20px'
        },
        signout: {
            width: '100%',
            borderRadius: '4px',
            height: 11,
            padding: 4,
            py: 3,
            marginTop: 1,
            _hover: {
                background: 'gray.50'
            }
        },
        signoutText: {
            fontSize: 'sm',
            fontWeight: 'normal'
        },
        signoutIcon: {
            marginRight: 2
        }
    },
    parts: ['container', 'content', 'bodyContainer', 'logo', 'icons', 'signout']
}
