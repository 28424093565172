export function generatePassword() {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz'
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const numbers = '0123456789'
    const symbols = '!@#$%^&*()-_=+[]{}|;:,.<>?'

    let password = ''

    // Ensure the password contains at least one of each required character type
    password += lowercase[Math.floor(Math.random() * lowercase.length)]
    password += uppercase[Math.floor(Math.random() * uppercase.length)]
    password += numbers[Math.floor(Math.random() * numbers.length)]
    password += symbols[Math.floor(Math.random() * symbols.length)]

    // Fill the rest of the password length with random characters from all categories
    const allCharacters = lowercase + uppercase + numbers + symbols

    for (let i = password.length; i < 10; i++) {
        password +=
            allCharacters[Math.floor(Math.random() * allCharacters.length)]
    }

    // Shuffle the password to ensure the required characters are not in a fixed position
    password = password
        .split('')
        .sort(() => 0.5 - Math.random())
        .join('')

    return password
}
