import React, {useMemo, useRef} from 'react'
import {
    Flex,
    Skeleton,
    useBreakpointValue,
    useDisclosure,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {AuthHelpers, useAuthHelper} from '@salesforce/commerce-sdk-react'
import useNavigation from '@salesforce/retail-react-app/app/hooks/use-navigation'
import {isHydrated} from '@salesforce/retail-react-app/app/utils/utils'
import AccountDropdown from './account-dropdown'
import Link from '../../link'
import {colors, styleConfigChakraKeys} from '../../../constants'
import {useCurrentCustomer} from '@salesforce/retail-react-app/app/hooks/use-current-customer'

const ENTER_KEY = 'Enter'
const SIGN_IN_HREF = '/login'

export const LoginAction = ({
    accountBtn,
    isRegistered = false,
    setShowLoading,
    showLoading = true
}) => {
    const navigate = useNavigation()
    const hasEnterPopoverContent = useRef()
    const {data: customer, isLoading: isLoadingCustomer} = useCurrentCustomer()
    const {isOpen, onClose, onOpen} = useDisclosure()

    const styles = useMultiStyleConfig(styleConfigChakraKeys.header)

    const logout = useAuthHelper(AuthHelpers.Logout)
    const isDesktop = useBreakpointValue({
        base: false,
        xl: true
    })

    const onSignoutClick = async () => {
        setShowLoading(true)
        await logout.mutateAsync()
        navigate('/login')
        setShowLoading(false)
    }

    const keyMap = {
        Escape: () => onClose(),
        Enter: () => onOpen()
    }

    const handleIconsMouseLeave = () =>
        setTimeout(() => {
            if (!hasEnterPopoverContent.current) onClose()
        }, 100)

    const onMyAccountKeyDown = (e) => e.key === ENTER_KEY && onMyAccountClick()

    const accountBtnSettings = useMemo(() => {
        const name = `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`
        const defaultText = accountBtn?.text ?? 'Account'
        const defaultIcon = accountBtn?.iconName
        const defaultBtnSettings = {
            type: accountBtn?.type ?? 'tertiary'
        }

        if (!isRegistered)
            return {
                ...accountBtn,
                'aria-label': defaultText,
                url: accountBtn?.url ?? SIGN_IN_HREF,
                buttonStyles: {
                    ...styles.iconBtn,
                    width: 'auto',
                    height: 'auto',
                    padding: '6px 26px',
                    backgroundColor: colors.black,
                    color: colors.white,
                    borderRadius: '34px',
                    fontWeight: 300
                },
                _hover: {
                    backgroundColor: colors.quilGray,
                    color: colors.black,
                }
            }

        const letters = name
            ?.toUpperCase()
            ?.split(' ')
            ?.map((word) => word[0])
            ?.join('')
            ?.slice(0, 2)
            ?.trim()

        if (letters === '' && defaultIcon)
            return {
                ...defaultBtnSettings,
                icon: defaultIcon,
                iconStyles: styles.accountIcon
            }

        return {
            ...defaultBtnSettings,
            buttonStyles: {
                ...styles.iconBtn,
                width: 'auto',
                height: 'auto'
            },
            text: (
                <Flex
                    width="40px"
                    height="40px"
                    borderRadius="40px"
                    justifyContent="center"
                    alignItems="center"
                    padding="15px 15.5px"
                    lineHeight="normal"
                    fontSize="14px"
                    fontWeight="300"
                    fontFamily="Roboto"
                    backgroundColor={colors.quilGray}
                    color={colors.black}
                >
                    {letters}
                </Flex>
            )
        }
    }, [isRegistered, customer, accountBtn])

    if (showLoading || (isRegistered && isLoadingCustomer))
        return (
            <Skeleton
                width="33px"
                height="33px"
                marginRight="20px"
                display={{base: 'none', xl: 'block'}}
            />
        )

    return (
        <>
            {accountBtnSettings && (
                <Link
                    buttonStyles={{
                        width: 'auto',
                        height: 'auto'
                    }}
                    {...accountBtnSettings}
                    tabIndex={0}
                    onMouseOver={isDesktop && isRegistered && onOpen}
                    onKeyDown={onMyAccountKeyDown}
                    display={{base: 'none', xl: 'block'}}
                    marginRight="7px"
                />
            )}

            {isRegistered && isHydrated() && (
                <AccountDropdown
                    isOpen={!!isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    handleIconsMouseLeave={handleIconsMouseLeave}
                    hasEnterPopoverContent={hasEnterPopoverContent}
                    keyMap={keyMap}
                    onSignoutClick={onSignoutClick}
                    styles={styles}
                />
            )}
        </>
    )
}
