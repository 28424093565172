import _ from 'lodash'
import React, {forwardRef, useEffect, useState} from 'react'
import {
    Box,
    List,
    ListItem,
    useMediaQuery
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Link from '../../link'
import './announcement-bar.scss'

const AnnouncementBar = forwardRef(({data}, ref) => {
    const [isDesktop] = useMediaQuery('(min-width: 992px)')
    const announcementItems = data?.notifications
    const [boxHeight, setBoxHeight] = useState('100%')
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false
    }

    useEffect(() => {
        if (!isDesktop) setBoxHeight(`${ref?.current?.offsetHeight ?? 30}px`)
    }, [isDesktop])

    if (!announcementItems?.length) return <></>

    if (announcementItems?.length === 1) {
        const {fields = null} = announcementItems?.at(-1) ?? null
        const theme = fields?.theme?.fields || {}

        return (
            <>
                {fields && (
                    <Box
                        position="relative"
                        width="100vw"
                        height="auto"
                        ref={ref}
                    >
                        <Box
                            padding={{base: '0', md: '12px 18px'}}
                            width="100vw"
                            maxWidth="100vw"
                            height={boxHeight}
                            backgroundColor={theme.backgroundColor || '#782B2F'}
                            textAlign="center"
                            display="flex"
                            justifyContent="center"
                        >
                            <Link
                                buttonStyles={{
                                    color: theme.color || 'white',
                                    backgroundColor: 'none',
                                    background: 'none',
                                    fontSize: {
                                        base: '16px',
                                        md: '14px'
                                    },
                                    fontWeight: {
                                        base: '400',
                                        md: '500'
                                    },
                                    whiteSpace: 'unset',
                                    wordBreak: 'break-word',
                                    lineHeight: '1.4',
                                    _hover: {
                                        textDecoration: 'none',
                                        backgroundColor: 'none',
                                        background: 'none'
                                    },
                                    textAlign: 'center',
                                    padding: 'none',
                                    height: '100%'
                                }}
                                url={
                                    fields.isExternal
                                        ? fields.externalUrl
                                        : fields.url
                                }
                                target={
                                    fields.openLinkInNewTab ? '_blank' : '_self'
                                }
                                rel={
                                    fields.openLinkInNewTab
                                        ? 'noopener noreferrer'
                                        : ''
                                }
                            >
                                {fields.text}
                            </Link>
                        </Box>
                    </Box>
                )}
            </>
        )
    }

    return (
        <Box position="relative" width="100vw" height="auto" ref={ref}>
            <List>
                <Slider
                    {...settings}
                    className="announcement-bar"
                    height="100%"
                >
                    {_.map(announcementItems, (announcement, index) => {
                        const {fields} = announcement
                        const theme = fields.theme?.fields || {}
                        return (
                            <ListItem
                                padding={{base: '0', md: '12px 18px'}}
                                key={announcement.sys.id}
                                width="100vw"
                                maxWidth="100vw"
                                height={boxHeight}
                                backgroundColor={
                                    theme.backgroundColor || '#782B2F'
                                }
                                textAlign="center"
                                display="flex"
                                justifyContent="center"
                            >
                                <Link
                                    buttonStyles={{
                                        color: theme.color || 'white',
                                        backgroundColor: 'none',
                                        background: 'none',
                                        fontSize: {
                                            base: '16px',
                                            md: '14px'
                                        },
                                        fontWeight: {
                                            base: '400',
                                            md: '500'
                                        },
                                        whiteSpace: 'unset',
                                        wordBreak: 'break-word',
                                        lineHeight: '1.4',
                                        _hover: {
                                            textDecoration: 'none',
                                            backgroundColor: 'none',
                                            background: 'none'
                                        },
                                        textAlign: 'center',
                                        padding: 'none',
                                        height: '100%'
                                    }}
                                    url={
                                        fields.isExternal
                                            ? fields.externalUrl
                                            : fields.url
                                    }
                                    target={
                                        fields.openLinkInNewTab
                                            ? '_blank'
                                            : '_self'
                                    }
                                    rel={
                                        fields.openLinkInNewTab
                                            ? 'noopener noreferrer'
                                            : ''
                                    }
                                >
                                    {fields.text}
                                </Link>
                            </ListItem>
                        )
                    })}
                </Slider>
            </List>
        </Box>
    )
})

AnnouncementBar.propTypes = {
    data: PropTypes.shape({
        notifications: PropTypes.arrayOf(PropTypes.object)
    })
}

AnnouncementBar.displayName = 'AnnouncementBar'

export default AnnouncementBar
